export const oidcSettings = {
  authority: process.env.VUE_APP_OAUTH2_ISSUER,
  scope: 'openid profile email',
  clientId: process.env.VUE_APP_OAUTH2_CLIENTID,
  redirectUri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/oidc-callback",
  responseType: 'id_token token',
  post_logout_redirect_uri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2],
  loadUserInfo: false,
  silent_redirect_uri: window.location.href.split('/')[0]+"//"+window.location.href.split('/')[2]+"/oidc-silent-renew",
  automaticSilentRenew: true,
}
